@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: rgb(255, 245, 224);
}

#auth {
    display: flex;
    flex-direction: column;
    justify-content: center; /* 수평 중앙 정렬 */
    align-items: center; /* 수직 중앙 정렬 */
    height: 100vh; /* 전체 화면 높이 */
    margin: 0;
}
/* 로그인 박스 스타일 */
.auth-container {
    background-color: #ffffff; /* 흰색 배경 */
    border-radius: 8px; /* 둥근 모서리 */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
    padding: 20px;
    width: 400px; /* 고정 너비 */
}

  /* 버튼 스타일 */
.auth-button {
    background-color: #4CAF50; /* 초록색 */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    width: 100%; /* 전체 너비 */
    font-size: 16px;
}

.auth-button:hover {
    background-color: #45a049; /* 호버 시 색상 변화 */
}

/* 입력 필드 스타일 */
input[type="email"],
input[type="password"] {
width: 100%; /* 전체 너비 */
padding: 10px;
margin: 10px 0; /* 위아래 여백 */
border: 1px solid #ccc; /* 경계선 */
border-radius: 4px; /* 둥근 모서리 */
}

/* 링크 스타일 */
a {
    text-decoration: none; /* 밑줄 제거 */
}

a:hover {
    text-decoration: underline; /* 호버 시 밑줄 추가 */
}

.download-button {
    display: flex;
    align-items: center;
    background-color: #4CAF50;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 4px;
    margin-bottom: 16px;
    font-size: 20px;
    transition: background-color 0.3s ease;
}

.download-button:hover {
    background-color: #45a049;
}

.app-icon {
    width: 60px;
    height: 60px;
    margin-right: 8px;
} 